import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCarSide } from "@fortawesome/free-solid-svg-icons";
import "./LoadingAnimation.css";  // Make sure to create this CSS file for styles

const LoadingAnimation = () => {
    return (
      <div className="loading-container">
        <div className="car">
          <FontAwesomeIcon icon={faCarSide} size="3x" />
          <div className="smoke"></div>
        </div>
        <div className="loading-text">Loading...</div>
      </div>
    );
};
  
export default LoadingAnimation;
