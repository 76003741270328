import React from 'react';

const GrossProfit = ({ profitData, selectedMonth, selectedYear, saleType, performancePlan }) => {
  const totalGrossProfit = profitData?.total_gross_profit ?? 'Data Missing';

  if (totalGrossProfit === 'Data Missing') {
    return <div>No Data Available</div>;
  }

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  // Format the selected month to display as a string (e.g., "January 2024")
  const monthName = new Date(selectedYear, selectedMonth - 1).toLocaleString('default', { month: 'long' });

  // Adjust how we find the performance metric based on saleType
  let performanceMetric = 0;

  if (saleType === 'new') {
    performanceMetric = performancePlan?.find(plan => plan.item === 'new gross profit')?.metric ?? 0;
  } else if (saleType === 'used') {
    performanceMetric = performancePlan?.find(plan => plan.item === 'used gross profit')?.metric ?? 0;
  } else if (saleType === 'all') {
    performanceMetric = performancePlan?.find(plan => plan.item === 'total gross profit')?.metric ?? 0;
  }

  // Calculate projected gross profit
  const projectedGrossProfit = (selectedMonth === currentMonth && selectedYear === currentYear)
    ? totalGrossProfit * (profitData.total_days ? (profitData.total_days / profitData.current_day) : 1)
    : totalGrossProfit;

  // Calculate the percentage of total gross profit to the performance plan with one decimal precision
  const percentage = performanceMetric ? ((projectedGrossProfit / performanceMetric) * 100).toFixed(1) : 'N/A';

  // Determine color based on percentage
  let percentageColor = '#ff0000'; // Default to red if < 96%
  if (percentage >= 100) {
    percentageColor = 'green';
  } else if (percentage >= 96 && percentage < 100) {
    percentageColor = '#cc5500'; // Burnt orange
  }

  return (
    <div className="bg-card">
      <h2 style={{ color: '#333' }}>
        {saleType === 'new' ? 'New' : saleType === 'used' ? 'Used' : 'All'} Gross Profit ({monthName} {selectedYear})
      </h2>
      <div className="gross-profit-container" style={{ display: 'flex', alignItems: 'center' }}>
        <div className="text-3xl" style={{ fontWeight: 'bold', marginRight: '10px' }}>
          ${totalGrossProfit.toLocaleString()}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
          <div 
            className="percentage-to-plan" 
            style={{ fontSize: '1.5rem', color: percentageColor }}
          >
            {percentage}%
          </div>
          <div style={{ fontSize: '0.8rem', marginLeft: '5px', color: '#666' }}>
            of objective
          </div>
        </div>
      </div>
      {selectedMonth === currentMonth && selectedYear === currentYear && (
        <p>
          Projected for Month End: 
          <span style={{ fontWeight: 'bold' }}> ${Math.round(projectedGrossProfit).toLocaleString()}</span>
        </p>
      )}
    </div>
  );
};

export default GrossProfit;
