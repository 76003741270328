import React, { useEffect, useState } from "react";
import './HomePage.css';

const HomePage = ({ user }) => {
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/overview-metrics`;

        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch metrics");
        }

        const data = await response.json();
        setMetrics(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchMetrics();
  }, []);

  const currentMonth = new Date().toLocaleString('default', { month: 'long', year: 'numeric' });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="home-page">
      <h2>Dealership Metrics for {currentMonth}</h2>
      <div className="kpis">
        <div className="kpi-item">
          <h3>Total Sales</h3>
          <p>{metrics?.total_sales}</p>
        </div>
        <div className="kpi-item">
          <h3>Total Gross Revenue</h3>
          <p>${metrics?.total_gross?.toLocaleString()}</p>
        </div>
        <div className="kpi-item">
          <h3>Total Inventory</h3>
          <p>{metrics?.total_inventory}</p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
