import React, { useEffect, useState } from 'react';
import './SalesReport.css';

const getCurrentMonth = () => {
  const date = new Date();
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
};

const SalesReport = () => {
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [month, setMonth] = useState(getCurrentMonth());
  const [totals, setTotals] = useState({
    new: {
      frontGross: 0,
      backGross: 0,
      totalGross: 0,
      unitCount: 0,
    },
    used: {
      frontGross: 0,
      backGross: 0,
      totalGross: 0,
      unitCount: 0,
    }
  });

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/sales-report?month=${month}`;

        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch sales data");
        }

        const data = await response.json();
        setSalesData(data);
        calculateTotals(data); // Calculate totals after fetching data
        setLoading(false);
      } catch (error) {
        console.error('Error fetching sales report data:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchSalesData();
  }, [month]);

  const calculateTotals = (data) => {
    const newTotals = { frontGross: 0, backGross: 0, totalGross: 0, unitCount: 0 };
    const usedTotals = { frontGross: 0, backGross: 0, totalGross: 0, unitCount: 0 };

    data.forEach(row => {
      const frontGross = parseFloat(row.front_gross?.value || row.front_gross || 0);
      const backGross = parseFloat(row.back_gross?.value || row.back_gross || 0);
      const saleType = row.sale_type?.value || row.sale_type;

      if (saleType === 'New') {
        newTotals.frontGross += frontGross;
        newTotals.backGross += backGross;
        newTotals.totalGross += frontGross + backGross;
        newTotals.unitCount += 1;
      } else if (saleType === 'Used') {
        usedTotals.frontGross += frontGross;
        usedTotals.backGross += backGross;
        usedTotals.totalGross += frontGross + backGross;
        usedTotals.unitCount += 1;
      }
    });

    setTotals({
      new: newTotals,
      used: usedTotals,
    });
  };

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const renderCell = (cellData) => {
    if (cellData && typeof cellData === 'object' && cellData.value !== undefined) {
      return cellData.value;
    }
    return cellData || '';
  };

  const formatCurrency = (value) => {
    return value.toLocaleString(undefined, { style: 'currency', currency: 'USD' });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="sales-report-container">
      <h2>Sales Report</h2>

      <label>
        Select Month:
        <input type="month" value={month} onChange={handleMonthChange} />
      </label>

      <div className="totals-container">
        <div className="totals-section new-vehicle">
          <h3>New Vehicle Totals</h3>
          <p>Total Front Gross: {formatCurrency(totals.new.frontGross)}</p>
          <p>Total Back Gross: {formatCurrency(totals.new.backGross)}</p>
          <p>Total Gross: {formatCurrency(totals.new.totalGross)}</p>
          <p>Units Sold: {totals.new.unitCount}</p>
        </div>

        <div className="totals-section used-vehicle">
          <h3>Used Vehicle Totals</h3>
          <p>Total Front Gross: {formatCurrency(totals.used.frontGross)}</p>
          <p>Total Back Gross: {formatCurrency(totals.used.backGross)}</p>
          <p>Total Gross: {formatCurrency(totals.used.totalGross)}</p>
          <p>Units Sold: {totals.used.unitCount}</p>
        </div>
      </div>

      <div className="table-wrapper">
        <table className="sales-report-table">
          <thead>
            <tr>
              <th>Deal Date</th>
              <th>Deal Number</th>
              <th>Customer First Name</th>
              <th>Customer Last Name</th>
              <th>VIN</th>
              <th>Stock Number</th>
              <th>Sale Type</th>
              <th>Incentive</th>
              <th>Front Gross</th>
              <th>Retail Price</th>
              {/* Add more headers as needed */}
            </tr>
          </thead>
          <tbody>
            {salesData.map((row, index) => (
              <tr key={index}>
                <td>{renderCell(row.deal_date)}</td>
                <td>{renderCell(row.deal_number)}</td>
                <td>{renderCell(row.customer_name_first)}</td>
                <td>{renderCell(row.customer_name_last)}</td>
                <td>{renderCell(row.vin_number)}</td>
                <td>{renderCell(row.stock_number)}</td>
                <td>{renderCell(row.sale_type)}</td>
                <td>{renderCell(row.incentive)}</td>
                <td>{formatCurrency(parseFloat(renderCell(row.front_gross)))}</td>
                <td>{renderCell(row.retail_price)}</td>
                {/* Render more fields as needed */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SalesReport;
