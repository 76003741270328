import React from 'react';

const TotalDeals = ({ salesData, selectedMonth, selectedYear, saleType, performancePlan }) => {
  const totalDeals = salesData?.total_deals ?? 'Data Missing';

  if (totalDeals === 'Data Missing') {
    return <div>No Data Available</div>;
  }

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  // Format the selected month to display as a string (e.g., "January 2024")
  const monthName = new Date(selectedYear, selectedMonth - 1).toLocaleString('default', { month: 'long' });

  // Adjust how we find the performance metric based on saleType
  let performanceMetric = 0;

  if (saleType === 'new') {
    performanceMetric = performancePlan?.find(plan => plan.item === 'new units sold')?.metric ?? 0;
  } else if (saleType === 'used') {
    performanceMetric = performancePlan?.find(plan => plan.item === 'used units sold')?.metric ?? 0;
  } else if (saleType === 'all') {
    performanceMetric = performancePlan?.find(plan => plan.item === 'total units sold')?.metric ?? 0;
  }

  // Calculate projected deals and round to the nearest whole number
  const projectedDeals = (selectedMonth === currentMonth && selectedYear === currentYear)
    ? Math.round(totalDeals * (salesData.total_days ? (salesData.total_days / salesData.current_day) : 1))
    : totalDeals;

  // Calculate the percentage of total deals to the performance plan with one decimal precision
  const percentage = performanceMetric ? ((projectedDeals / performanceMetric) * 100).toFixed(1) : 'N/A';

  // Determine color based on percentage
  let percentageColor = '#ff0000'; // Default to red if < 96%
  if (percentage >= 100) {
    percentageColor = 'green';
  } else if (percentage >= 96 && percentage < 100) {
    percentageColor = '#cc5500'; // Burnt orange
  }

  return (
    <div className="bg-card">
      <h2 style={{ color: '#333' }}>
        {saleType === 'new' ? 'New' : saleType === 'used' ? 'Used' : 'All'} Total Deals ({monthName} {selectedYear})
      </h2>
      <div className="total-deals-container" style={{ display: 'flex', alignItems: 'center' }}>
        <div className="text-3xl" style={{ fontWeight: 'bold', marginRight: '10px' }}>{totalDeals.toLocaleString()}</div>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
          <div 
            className="percentage-to-plan" 
            style={{ fontSize: '1.5rem', color: percentageColor }}
          >
            {percentage}% {/* Show percentage next to totalDeals with a smaller font */}
          </div>
          <div style={{ fontSize: '0.8rem', marginLeft: '5px', color: '#666' }}>
            of objective {/* Small text to the right of the percentage */}
          </div>
        </div>
      </div>
      {selectedMonth === currentMonth && selectedYear === currentYear && (
        <p>
          Projected for Month End: 
          <span style={{ fontWeight: 'bold' }}> {projectedDeals.toLocaleString()}</span>
        </p>
      )}
    </div>
  );
};

export default TotalDeals;
