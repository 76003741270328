import React, { useState, useEffect } from 'react';
import './PersonnelData.css';

const PersonnelData = () => {
  const [personnelData, setPersonnelData] = useState([]);
  const [newRecord, setNewRecord] = useState({
    employee_id: '',
    employee_name_first: '',
    employee_name_last: '',
    role: '',
    title: '',
    department: '',
    status: 'Active',
  });
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    const fetchPersonnelData = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/personnel-data`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch personnel data');
        }

        const data = await response.json();
        setPersonnelData(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchPersonnelData();
  }, []);

  const handleSort = (columnKey) => {
    let direction = 'ascending';
    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: columnKey, direction });

    const sortedData = [...personnelData].sort((a, b) => {
      if (a[columnKey] < b[columnKey]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[columnKey] > b[columnKey]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setPersonnelData(sortedData);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setSelectedRecord({
      ...selectedRecord,
      [name]: value,
    });
  };

  const handleNewRecordChange = (e) => {
    const { name, value } = e.target;
    setNewRecord({
      ...newRecord,
      [name]: value,
    });
  };

  const handleUpdate = async () => {
    try {
      const token = sessionStorage.getItem('accessToken');
      const updatedRecord = {
        ...selectedRecord,
        updated_at: new Date().toISOString(),
      };

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/update-personnel-record`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ updatedRecord }),
      });

      if (!response.ok) {
        throw new Error('Failed to update record');
      }

      alert('Record updated successfully!');
      setSelectedRecord(null);  // Clear selected record after update
    } catch (err) {
      alert(`Error updating record: ${err.message}`);
    }
  };

  const handleAddRecord = async () => {
    try {
      const token = sessionStorage.getItem('accessToken');
      const newRecordWithTimestamp = {
        ...newRecord,
        updated_at: new Date().toISOString(),
      };

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/add-personnel-record`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ newRecord: newRecordWithTimestamp }),
      });

      if (!response.ok) {
        throw new Error('Failed to add new record');
      }

      alert('New record added successfully!');
      setNewRecord({
        employee_id: '',
        employee_name_first: '',
        employee_name_last: '',
        role: '',
        title: '',
        department: '',
        status: 'Active',
      }); // Reset form
    } catch (err) {
      alert(`Error adding record: ${err.message}`);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="personnel-data-container">
      <h2>Personnel Data</h2>

      {/* Display personnel table */}
      <table className="personnel-table">
        <thead>
          <tr>
            <th onClick={() => handleSort('employee_id')}>
              Employee ID {sortConfig.key === 'employee_id' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('employee_name_first')}>
              First Name {sortConfig.key === 'employee_name_first' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('employee_name_last')}>
              Last Name {sortConfig.key === 'employee_name_last' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('role')}>
              Role {sortConfig.key === 'role' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('title')}>
              Title {sortConfig.key === 'title' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('department')}>
              Department {sortConfig.key === 'department' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('status')}>
              Status {sortConfig.key === 'status' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('updated_at')}>
              Last Updated {sortConfig.key === 'updated_at' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {personnelData.map((record) => (
            <tr key={record.employee_id}>
              <td>{record.employee_id}</td>
              <td>{record.employee_name_first}</td>
              <td>{record.employee_name_last}</td>
              <td>{record.role}</td>
              <td>{record.title}</td>
              <td>{record.department}</td>
              <td>{record.status}</td>
              <td>{record.updated_at?.value || record.updated_at}</td> {/* Handling the updated_at field */}
              <td>
                <button onClick={() => setSelectedRecord(record)}>Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Edit selected record */}
      {selectedRecord && (
        <div className="update-section">
          <h3>Edit Record</h3>
          <label>
            First Name:
            <input
              type="text"
              name="employee_name_first"
              value={selectedRecord.employee_name_first}
              onChange={handleFieldChange}
            />
          </label>
          <label>
            Last Name:
            <input
              type="text"
              name="employee_name_last"
              value={selectedRecord.employee_name_last}
              onChange={handleFieldChange}
            />
          </label>
          <label>
            Role:
            <input
              type="text"
              name="role"
              value={selectedRecord.role}
              onChange={handleFieldChange}
            />
          </label>
          <label>
            Title:
            <input
              type="text"
              name="title"
              value={selectedRecord.title}
              onChange={handleFieldChange}
            />
          </label>
          <label>
            Department:
            <input
              type="text"
              name="department"
              value={selectedRecord.department}
              onChange={handleFieldChange}
            />
          </label>
          <label>
            Status:
            <input
              type="text"
              name="status"
              value={selectedRecord.status}
              onChange={handleFieldChange}
            />
          </label>
          <button onClick={handleUpdate}>Update</button>
        </div>
      )}

      {/* Add new record */}
      <div className="add-section">
        <h3>Add New Record</h3>
        <label>
          Employee ID:
          <input
            type="number"
            name="employee_id"
            value={newRecord.employee_id}
            onChange={handleNewRecordChange}
          />
        </label>
        <label>
          First Name:
          <input
            type="text"
            name="employee_name_first"
            value={newRecord.employee_name_first}
            onChange={handleNewRecordChange}
          />
        </label>
        <label>
          Last Name:
          <input
            type="text"
            name="employee_name_last"
            value={newRecord.employee_name_last}
            onChange={handleNewRecordChange}
          />
        </label>
        <label>
          Role:
          <input
            type="text"
            name="role"
            value={newRecord.role}
            onChange={handleNewRecordChange}
          />
        </label>
        <label>
          Title:
          <input
            type="text"
            name="title"
            value={newRecord.title}
            onChange={handleNewRecordChange}
          />
        </label>
        <label>
          Department:
          <input
            type="text"
            name="department"
            value={newRecord.department}
            onChange={handleNewRecordChange}
          />
        </label>
        <label>
          Status:
          <input
            type="text"
            name="status"
            value={newRecord.status}
            onChange={handleNewRecordChange}
          />
        </label>
        <button onClick={handleAddRecord}>Add Record</button>
      </div>
    </div>
  );
};

export default PersonnelData;
