import React, { useState, useEffect } from "react";
import "./InventoryForm.css";

const InventoryForm = () => {
  const [formData, setFormData] = useState({
    stock_in_date: '',
    stock_number: '',
    vin_number: '',
    sale_type: '',
    marketed_status: '',
    inventory_cost: '',
    sale_price: '',
    internet_price: '',
    make: '',
    model: '',
    model_year: '',
    submitted_at: ''
  });

  const [fetchedData, setFetchedData] = useState(null);
  const [modelYears, setModelYears] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [searchId, setSearchId] = useState('');
  const [searchType, setSearchType] = useState('stock_number');
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [showFetchedData, setShowFetchedData] = useState(false);

  const vehicleMakes = [
    "Toyota", "Honda", "Ford", "Chevrolet", "Nissan", "Hyundai", "Kia", "Volkswagen",
    "Subaru", "Mazda", "BMW", "Mercedes-Benz", "Audi", "Lexus", "Jeep", "Dodge",
    "Chrysler", "GMC", "Buick", "Cadillac"
  ].sort();

  const handleSearch = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/fetch-record-by-id?${searchType}=${searchId}`;
      console.log('Requesting:', url);
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setFetchedData(data[0] || {});
        setIsDataFetched(true);
        setShowFetchedData(true);
      } else {
        console.error("Failed to fetch data by ID");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 42 }, (_, i) => currentYear + 2 - i);
    setModelYears(years);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('accessToken');
    if (!token) {
      console.error('No token found');
      return;
    }

    // Get the current timestamp
    const currentTimestamp = new Date().toISOString();

    // Update the formData with the current timestamp
    const updatedFormData = {
      ...formData,
      submitted_at: currentTimestamp,
      status: "available"
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/submit-inventory`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ formData: updatedFormData }),
      });

      if (response.ok) {
        console.log("Data inserted successfully");
        // Reset the form data including the submitted_at field
        setFormData({
          stock_in_date: '',
          stock_number: '',
          vin_number: '',
          sale_type: '',
          marketed_status: '',
          inventory_cost: '',
          sale_price: '',
          internet_price: '',
          make: '',
          model: '',
          model_year: '',
          submitted_at: ''
        });
        setIsDataFetched(false);
      } else {
        console.error("Failed to insert data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const populateForm = () => {
    if (fetchedData) {
      setFormData({
        stock_in_date: fetchedData.stock_in_date?.value || '',
        stock_number: fetchedData.stock_number || '',
        vin_number: fetchedData.vin_number || '',
        sale_type: fetchedData.sale_type || '',
        marketed_status: fetchedData.marketed_status || '',
        inventory_cost: fetchedData.inventory_cost || '',
        sale_price: fetchedData.sale_price || '',
        internet_price: fetchedData.internet_price || '',
        make: fetchedData.make || '',
        model: fetchedData.model || '',
        model_year: fetchedData.model_year || ''
      });
      setIsDataFetched(false);
    }
  };

  return (
    <div className="inventory-form-container">
      <button className="centered-button" onClick={() => setShowSearch(!showSearch)}>
        {showSearch ? "Hide Search" : "Show Search"}
      </button>

      {showSearch && (
        <div className="search-section centered">
          <h3>Search Record by ID</h3>
          <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
            <option value="stock_number">Stock Number</option>
            <option value="vin_number">VIN</option>
          </select>
          <input
            type="text"
            placeholder={`Enter ${searchType}`}
            value={searchId}
            onChange={(e) => setSearchId(e.target.value)}
          />
          <button className="centered-button" onClick={handleSearch}>Search</button>
        </div>
      )}

      <div className="form-data-section">
        {showFetchedData && (
          <div className="fetched-data centered">
            <h3>Fetched Data:</h3>
            <p>Date Stocked In: {fetchedData.stock_in_date?.value || 'N/A'}</p>
            <p>Stock Number: {fetchedData.stock_number || 'N/A'}</p>
            <p>VIN: {fetchedData.vin_number || 'N/A'}</p>
            <p>Sale Type: {fetchedData.sale_type || 'N/A'}</p>
            <p>Marketed Status: {fetchedData.marketed_status || 'N/A'}</p>
            <p>Cost: {fetchedData.inventory_cost || 'N/A'}</p>
            <p>Sale Price: {fetchedData.sale_price || 'N/A'}</p>
            <p>Internet Price: {fetchedData.internet_price || 'N/A'}</p>
            <p>Make: {fetchedData.make || 'N/A'}</p>
            <p>Model: {fetchedData.model || 'N/A'}</p>
            <p>Model Year: {fetchedData.model_year || 'N/A'}</p>

            <button className="centered-button" onClick={populateForm}>
              Populate Form with Fetched Data
            </button>
            <button className="centered-button" onClick={() => setShowFetchedData(false)}>
              Hide Fetched Data
            </button>
          </div>
        )}

        <form onSubmit={handleSubmit} className="inventory-form">
          <div className="form-header-container">
            <h2 className="form-header">Inventory</h2>
            <h3 className="form-header">add or update</h3> 
          </div>
          <label>
            Date Stocked In:
            <input
              type="date"
              name="stock_in_date"
              value={formData.stock_in_date}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Stock Number:
            <input
              type="text"
              name="stock_number"
              value={formData.stock_number}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            VIN:
            <input
              type="text"
              name="vin_number"
              value={formData.vin_number}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Sale Type:
            <select
              name="sale_type"
              value={formData.sale_type}
              onChange={handleChange}
              required
            >
              <option value="">Select New or Used</option>
              <option value="New">New</option>
              <option value="Used">Used</option>
            </select>
          </label>
          <label>
            Marketed Status:
            <select
              name="marketed_status"
              value={formData.marketed_status}
              onChange={handleChange}
            >
              <option value="">Select Status</option>
              <option value="Retail">Retail</option>
              <option value="Commercial">Commercial</option>
              <option value="Wholesale">Wholesale</option>
            </select>
          </label>
          <label>
            Cost:
            <input
              type="number"
              name="inventory_cost"
              value={formData.inventory_cost}
              onChange={handleChange}
            />
          </label>
          <label>
            Sale Price:
            <input
              type="number"
              name="sale_price"
              value={formData.sale_price}
              onChange={handleChange}
            />
          </label>
          <label>
            Internet Price:
            <input
              type="number"
              name="internet_price"
              value={formData.internet_price}
              onChange={handleChange}
            />
          </label>
          <label>
            Vehicle Make:
            <select
              name="make"
              value={formData.make}
              onChange={handleChange}
            >
              <option value="" disabled>Select Make</option>
              {vehicleMakes
                .filter(make => formData.sale_type === 'New' ? make === 'Lexus' : make !== 'Lexus')
                .map(make => (
                  <option key={make} value={make}>{make}</option>
                ))}
            </select>
          </label>
          <label>
            Vehicle Model:
            <input
              type="text"
              name="model"
              value={formData.model}
              onChange={handleChange}
            />
          </label>
          <label>
            Model Year:
            <select
              name="model_year"
              value={formData.model_year}
              onChange={handleChange}
            >
              <option value="" disabled>Select Model Year</option>
              {modelYears.map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </label>
          <button type="submit" className="centered-button">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default InventoryForm;
