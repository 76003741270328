// src/components/MainLayout.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartPie,
  faFileAlt,
  faDatabase,
  faChevronLeft,
  faChevronRight,
  faListOl,
  faTrophy,
  faUserShield, // Changed icon for Admin
} from '@fortawesome/free-solid-svg-icons';
import './MainLayout.css';
import logoLong from './logo_long.png';
import logoTall from './logo_tall.png'; // Import the tall logo for mobile

const MainLayout = ({ children, onLogout, user }) => {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);

  const toggleSidebar = () => {
    if (isSidebarVisible) {
      setExpandedGroup(null); // Collapse any expanded category
    }
    setSidebarVisible(!isSidebarVisible);
  };

  const handleGroupToggle = (group) => {
    if (!isSidebarVisible) {
      setSidebarVisible(true);
    } else {
      setExpandedGroup(expandedGroup === group ? null : group);
    }
    setSelectedSection(group);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  return (
    <div className="main-layout">
      <header className="header">
        <div className="header-left">
          <Link to="/">
            {/* Two images, one for wide screens, one for mobile */}
            <img src={logoLong} alt="Logo" className="logo-long" />
            <img src={logoTall} alt="Logo" className="logo-tall" />
          </Link>
          {/* Display rooftop if available, else default to 'OptiSight Software' */}
          <h1 className="title">{user?.rooftop || 'OptiSight Software'}</h1>
        </div>
        <div className="header-right">
          <div className="account-dropdown">
            <div onClick={toggleDropdown} className="account-link">
              <span className="account-name">{user?.firstName}'s</span>
              <span className="account-text">Account</span>
            </div>
            {isDropdownOpen && (
              <div className="dropdown-content">
                <Link to="/account-details" onClick={closeDropdown}>
                  Account Details
                </Link>
                <button
                  onClick={() => {
                    onLogout();
                    closeDropdown();
                  }}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </header>
      <div className="layout-content">
        <div className={`side-nav ${isSidebarVisible ? '' : 'collapsed'}`}>
          <button className="toggle-button" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={isSidebarVisible ? faChevronLeft : faChevronRight} />
          </button>
          <ul>
            {/* Conditionally render Admin menu for users with 'admin' role */}
            {(user.userRole === 'admin' || user.permissions.includes('accessAdminSection')) && (
              <li
                onClick={() => handleGroupToggle('Admin')}
                className={`${expandedGroup === 'Admin' ? 'expanded' : ''} ${
                  selectedSection === 'Admin' ? 'selected' : ''
                }`}
              >
                <div>
                  <FontAwesomeIcon icon={faUserShield} className="icon" />
                  {isSidebarVisible && 'Admin'}
                </div>
                {expandedGroup === 'Admin' && isSidebarVisible && (
                  <ul className="nested">
                    <li>
                      <Link to="/admin/switchboard" onClick={() => setSelectedSection('Admin')}>
                        Switchboard
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            )}

            {/* Dashboards Menu */}
            {user.permissions.includes('accessDashboards') && (
              <li
                onClick={() => handleGroupToggle('Dashboards')}
                className={`${expandedGroup === 'Dashboards' ? 'expanded' : ''} ${
                  selectedSection === 'Dashboards' ? 'selected' : ''
                }`}
              >
                <div>
                  <FontAwesomeIcon icon={faChartPie} className="icon" />
                  {isSidebarVisible && 'Dashboards'}
                </div>
                {expandedGroup === 'Dashboards' && isSidebarVisible && (
                  <ul className="nested">
                    {user.permissions.includes('viewSalesDashboard') && (
                      <li>
                        <Link
                          to="/dashboards/sales"
                          onClick={() => setSelectedSection('Dashboards')}
                        >
                          Sales
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes('viewCustomerDashboard') && (
                      <li>
                        <Link
                          to="/dashboards/customer"
                          onClick={() => setSelectedSection('Dashboards')}
                        >
                          Customer
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes('viewInventoryDashboard') && (
                      <li>
                        <Link
                          to="/dashboards/inventory"
                          onClick={() => setSelectedSection('Dashboards')}
                        >
                          Inventory
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}

            {/* Forms Menu */}
            {user.permissions.includes('accessForms') && (
              <li
                onClick={() => handleGroupToggle('Forms')}
                className={`${expandedGroup === 'Forms' ? 'expanded' : ''} ${
                  selectedSection === 'Forms' ? 'selected' : ''
                }`}
              >
                <div>
                  <FontAwesomeIcon icon={faFileAlt} className="icon" />
                  {isSidebarVisible && 'Forms'}
                </div>
                {expandedGroup === 'Forms' && isSidebarVisible && (
                  <ul className="nested">
                    {user.permissions.includes('submitSalesForm') && (
                      <li>
                        <Link to="/forms/sales" onClick={() => setSelectedSection('Forms')}>
                          Sales
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes('submitCustomerForm') && (
                      <li>
                        <Link to="/forms/customer" onClick={() => setSelectedSection('Forms')}>
                          Customer
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes('submitInventoryForm') && (
                      <li>
                        <Link to="/forms/inventory" onClick={() => setSelectedSection('Forms')}>
                          Inventory
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}

            {/* Data Menu */}
            {user.permissions.includes('accessData') && (
              <li
                onClick={() => handleGroupToggle('Data')}
                className={`${expandedGroup === 'Data' ? 'expanded' : ''} ${
                  selectedSection === 'Data' ? 'selected' : ''
                }`}
              >
                <div>
                  <FontAwesomeIcon icon={faDatabase} className="icon" />
                  {isSidebarVisible && 'Data'}
                </div>
                {expandedGroup === 'Data' && isSidebarVisible && (
                  <ul className="nested">
                    {user.permissions.includes('viewInventoryData') && (
                      <li>
                        <Link to="/data/inventory" onClick={() => setSelectedSection('Data')}>
                          Inventory
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes('viewPersonnelData') && (
                      <li>
                        <Link to="/data/personnel" onClick={() => setSelectedSection('Data')}>
                          Personnel
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}

            {/* Leaders Menu */}
            {user.permissions.includes('accessLeaders') && (
              <li
                onClick={() => handleGroupToggle('Leaders')}
                className={`${expandedGroup === 'Leaders' ? 'expanded' : ''} ${
                  selectedSection === 'Leaders' ? 'selected' : ''
                }`}
              >
                <div>
                  <FontAwesomeIcon icon={faTrophy} className="icon" />
                  {isSidebarVisible && 'Leaders'}
                </div>
                {expandedGroup === 'Leaders' && isSidebarVisible && (
                  <ul className="nested">
                    {user.permissions.includes('viewVariableLeaders') && (
                      <li>
                        <Link to="/leaders/variable" onClick={() => setSelectedSection('Leaders')}>
                          Variable
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes('viewFixedLeaders') && (
                      <li>
                        <Link to="/leaders/fixed" onClick={() => setSelectedSection('Leaders')}>
                          Fixed
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}

            {/* Reports Menu */}
            {user.permissions.includes('accessReports') && (
              <li
                onClick={() => handleGroupToggle('Reports')}
                className={`${expandedGroup === 'Reports' ? 'expanded' : ''} ${
                  selectedSection === 'Reports' ? 'selected' : ''
                }`}
              >
                <div>
                  <FontAwesomeIcon icon={faListOl} className="icon" />
                  {isSidebarVisible && 'Reports'}
                </div>
                {expandedGroup === 'Reports' && isSidebarVisible && (
                  <ul className="nested">
                    {user.permissions.includes('viewSalesReport') && (
                      <li>
                        <Link to="/reports/sales" onClick={() => setSelectedSection('Reports')}>
                          Sales Report
                        </Link>
                      </li>
                    )}
                    {/* Add more report links here if needed */}
                  </ul>
                )}
              </li>
            )}
          </ul>
        </div>
        <div className={`content ${isSidebarVisible ? 'expanded' : 'collapsed'}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
