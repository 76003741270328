// ChangePasswordPage.js
import React, { useState } from 'react';
import './ChangePasswordPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import loginLogo from './login_logo.png'; // Replace with the path to your logo file

const ChangePasswordPage = ({ onChangePassword }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const token = localStorage.getItem('accessToken'); // Ensure this key matches what's used in LoginPage

      if (!token) {
        setError('No authorization token found');
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/change-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ newPassword }),
      });

      if (response.ok) {
        // Clear error message if any
        setError('');
        // Update localStorage or state as needed
        localStorage.setItem('firstLogin', 'false'); // Ensure the firstLogin is set to false
        onChangePassword(); // Call the onChangePassword prop function
      } else if (response.status === 403) {
        setError('Invalid token or session expired. Please log in again.');
        localStorage.removeItem('accessToken'); // Clear the token on 403
        localStorage.removeItem('firstLogin');
        // Optionally, you can redirect to the login page here
      } else {
        setError('Error changing password');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setError('An error occurred. Please try again.');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className="change-password-page">
      <div className="change-password-container">
        <img src={loginLogo} alt="Login-Logo" className="login-logo" />
        <h1 className="change-password-title">Change Password</h1>
        <form onSubmit={handleSubmit} className="change-password-form">
          <div className="change-password-field">
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              onClick={toggleShowPassword}
              className="change-password-toggle-icon"
            />
          </div>
          <div className="change-password-field">
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              onClick={toggleShowPassword}
              className="change-password-toggle-icon"
            />
          </div>
          <button type="submit">Change Password</button>
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
