// src/pages/Admin/SwitchboardPage.js

import React, { useEffect, useState } from 'react';
import './SwitchBoardPage.css'; // Optional CSS for styling

const SwitchboardPage = ({ user }) => {
  const [users, setUsers] = useState([]);
  const [permissionsList, setPermissionsList] = useState([]);

  useEffect(() => {
    // Fetch all users
    fetchUsers();
    // Fetch available permissions (optional, depending on your backend)
    fetchPermissionsList();
  }, [user.token]);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchPermissionsList = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/permissions`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch permissions list');
      }
      const data = await response.json();
      setPermissionsList(data);
    } catch (error) {
      console.error('Error fetching permissions list:', error);
    }
  };

  const handlePermissionChange = (userId, permission, isChecked) => {
    const updatedUsers = users.map((u) => {
      if (u.id === userId) {
        const updatedPermissions = isChecked
          ? [...u.permissions, permission]
          : u.permissions.filter((perm) => perm !== permission);
        return { ...u, permissions: updatedPermissions };
      }
      return u;
    });
    setUsers(updatedUsers);
  };

  const savePermissions = async (userId) => {
    const userToUpdate = users.find((u) => u.id === userId);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/${userId}/permissions`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`,
        },
        body: JSON.stringify({ permissions: userToUpdate.permissions }),
      });
      if (!response.ok) {
        throw new Error('Failed to update permissions');
      }
      alert('Permissions updated successfully.');
    } catch (error) {
      console.error('Error updating permissions:', error);
      alert('Failed to update permissions.');
    }
  };

  return (
    <div className="switchboard-page">
      <h1>User Privileges Switchboard</h1>
      <table className="switchboard-table">
        <thead>
          <tr>
            <th>Username</th>
            <th>Role</th>
            {permissionsList.map((permission) => (
              <th key={permission}>{permission}</th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((u) => (
            <tr key={u.id}>
              <td>{u.username}</td>
              <td>{u.userRole}</td>
              {permissionsList.map((permission) => (
                <td key={`${u.id}-${permission}`}>
                  <input
                    type="checkbox"
                    checked={u.permissions.includes(permission)}
                    onChange={(e) => handlePermissionChange(u.id, permission, e.target.checked)}
                  />
                </td>
              ))}
              <td>
                <button onClick={() => savePermissions(u.id)}>Save</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SwitchboardPage;
