import React, { useEffect, useState } from 'react';
import './VariableLeaders.css'; // Ensure your CSS file is linked

const VariableLeaders = () => {
  const currentMonth = new Date().toISOString().slice(0, 7); // Get current month as YYYY-MM
  const [salesLeaders, setSalesLeaders] = useState(null);
  const [fiLeaders, setFiLeaders] = useState(null);
  const [managerLeaders, setManagerLeaders] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth); // Default to current month
  const [selectedSaleType, setSelectedSaleType] = useState('All');

  const months = (() => {
    const monthsArray = [];
    const today = new Date();
    for (let i = 0; i < 13; i++) {
      const month = new Date(today.getFullYear(), today.getMonth() - i, 1);
      const monthString = month.toISOString().slice(0, 7); // Format as YYYY-MM
      monthsArray.push(monthString);
    }
    return monthsArray;
  })();

  const saleTypes = ['All', 'New', 'Used'];

  useEffect(() => {
    const fetchLeaders = async () => {
      setLoading(true); // Show loading while fetching data
      try {
        const token = sessionStorage.getItem('accessToken');
        const baseUrl = process.env.REACT_APP_BACKEND_URL;
        const queryParams = `?month=${selectedMonth}&sale_type=${selectedSaleType}`;

        const salesApiUrl = `${baseUrl}/api/leaderboard-salespeople${queryParams}`;
        const fiApiUrl = `${baseUrl}/api/leaderboard-fi-producers${queryParams}`;
        const managerApiUrl = `${baseUrl}/api/leaderboard-sales-managers${queryParams}`;

        // Fetch sales leaderboard
        const salesResponse = await fetch(salesApiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!salesResponse.ok) {
          throw new Error("Failed to fetch salesperson leaderboard data");
        }

        const salesData = await salesResponse.json();
        setSalesLeaders(salesData);

        // Fetch FI leaderboard
        const fiResponse = await fetch(fiApiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!fiResponse.ok) {
          throw new Error("Failed to fetch FI producer leaderboard data");
        }

        const fiData = await fiResponse.json();
        setFiLeaders(fiData);

        // Fetch Sales Manager leaderboard
        const managerResponse = await fetch(managerApiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!managerResponse.ok) {
          throw new Error("Failed to fetch sales manager leaderboard data");
        }

        const managerData = await managerResponse.json();
        setManagerLeaders(managerData);

        setLoading(false); // Hide loading after fetching data
      } catch (error) {
        setError(error.message);
        setLoading(false); // Hide loading in case of error
      }
    };

    fetchLeaders();
  }, [selectedMonth, selectedSaleType]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // Helper function to format numbers as currency
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  return (
    <div className="leaderboard-container">
      <h2>Variable Leaders</h2>

      {/* Filters */}
      <div className="filters">
        <label>
          Select Month:
          <select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
            {months.map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
        </label>

        <label>
          Select Sale Type:
          <select value={selectedSaleType} onChange={(e) => setSelectedSaleType(e.target.value)}>
            {saleTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </label>
      </div>

      <h2>Salesperson Leaderboard</h2>
      <table className="leaderboard-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Salesperson</th>
            <th>Units Sold</th>
          </tr>
        </thead>
        <tbody>
          {salesLeaders && salesLeaders.map((leader, index) => (
            <tr key={leader.salesperson} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
              <td>{index + 1}</td>
              <td>{leader.salesperson}</td>
              <td>{leader.units_sold}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2>FI Producer Leaderboard</h2>
      <table className="leaderboard-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>FI Producer</th>
            <th>PVR</th>
          </tr>
        </thead>
        <tbody>
          {fiLeaders && fiLeaders.map((leader, index) => (
            <tr key={leader.fi_producer} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
              <td>{index + 1}</td>
              <td>{leader.fi_producer}</td>
              <td>{leader.pvr ? formatCurrency(leader.pvr) : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2>Sales Manager Leaderboard</h2>
      <table className="leaderboard-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Sales Manager</th>
            <th>Average Front Gross</th>
          </tr>
        </thead>
        <tbody>
          {managerLeaders && managerLeaders.map((leader, index) => (
            <tr key={leader.sales_manager} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
              <td>{index + 1}</td>
              <td>{leader.sales_manager}</td>
              <td>{leader.avg_front_gross ? formatCurrency(leader.avg_front_gross) : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VariableLeaders;
