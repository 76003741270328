import React, { useEffect, useState } from 'react';
import './InventoryData.css'; // Custom CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faDownload, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'; // Font Awesome download and sorting icons
import jsPDF from 'jspdf'; // For generating PDF files
import 'jspdf-autotable'; // For generating tables in PDF

const InventoryData = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [saleType, setSaleType] = useState('');
  const [stockNumber, setStockNumber] = useState(''); // New state for stock number filter
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showUpdateSection, setShowUpdateSection] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState(null); // Track the current sorted column
  const [sortDirection, setSortDirection] = useState('asc'); // Track sort direction
  const [showDownloadOptions, setShowDownloadOptions] = useState(false); // State to control download dropdown visibility
  const recordsPerPage = 50;

  useEffect(() => {
    const fetchInventoryData = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/inventory-data`;
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch inventory data");
        }

        const data = await response.json();
        setInventoryData(data);
        setFilteredData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchInventoryData();
  }, []);

  const handleFilter = () => {
    const filtered = inventoryData.filter(record => {
      const recordDate = new Date(record.stock_in_date?.value || record.stock_in_date);
      const isDateInRange = (!startDate || new Date(startDate) <= recordDate) && 
                            (!endDate || new Date(endDate) >= recordDate);
      const isSaleTypeMatching = !saleType || record.sale_type === saleType;
      const isStockNumberMatching = !stockNumber || record.stock_number.includes(stockNumber); // Stock number filter logic
      
      return isDateInRange && isSaleTypeMatching && isStockNumberMatching;
    });
    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page after filtering
  };

  const handleRecordSelect = (record) => {
    const recordWithValues = {};
    Object.keys(record).forEach(key => {
      if (key !== 'submitted_at') {
        recordWithValues[key] = record[key]?.value || record[key];
      }
    });

    setSelectedRecord(recordWithValues);
    setShowUpdateSection(true); // Automatically expand the update section
  };

  const handleFieldChange = (e) => {
    setSelectedRecord({
      ...selectedRecord,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdate = async () => {
    try {
      const token = sessionStorage.getItem('accessToken');
      const updatedRecord = {
        ...selectedRecord,
        submitted_at: new Date().toISOString(),
      };

      const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/update-inventory-record`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ updatedRecord }),
      });

      if (!response.ok) {
        throw new Error("Failed to update record");
      }

      alert('Record updated successfully!');
      setShowUpdateSection(false); // Hide the update section after successful update
    } catch (error) {
      alert('Error updating record: ' + error.message);
    }
  };

  const handleCellClick = (record) => {
    handleRecordSelect(record); // Select entire record when any cell is clicked
  };

  const handleSort = (column) => {
    const newSortDirection = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(newSortDirection);

    const sortedData = [...filteredData].sort((a, b) => {
      const aValue = a[column]?.value || a[column];
      const bValue = b[column]?.value || b[column];

      if (newSortDirection === 'asc') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });

    setFilteredData(sortedData);
  };

  const getSortIcon = (column) => {
    if (sortColumn === column) {
      return sortDirection === 'asc' ? faSortUp : faSortDown;
    }
    return null;
  };

  const handleDownloadCSV = () => {
    const csvContent = generateCSV(filteredData);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'inventory_data.csv');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setShowDownloadOptions(false); // Close the dropdown after download
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF({ orientation: 'landscape' });

    // Define the table headers based on your inventory data columns
    const columns = [
      { header: 'Date Stocked', dataKey: 'stock_in_date' },
      { header: 'Stock Number', dataKey: 'stock_number' },
      { header: 'VIN', dataKey: 'vin_number' },
      { header: 'Sale Type', dataKey: 'sale_type' },
      { header: 'Marketed As', dataKey: 'marketed_status' },
      { header: 'Inventory Cost', dataKey: 'inventory_cost' },
      { header: 'Sale Price', dataKey: 'sale_price' },
      { header: 'Internet Price', dataKey: 'internet_price' },
      { header: 'Make', dataKey: 'make' },
      { header: 'Model', dataKey: 'model' },
      { header: 'Model Year', dataKey: 'model_year' },
      // { header: 'Last Updated', dataKey: 'submitted_at' }
    ];

    // Map through the filteredData and retrieve all the column values
    const rows = filteredData.map(record => ({
      stock_in_date: record.stock_in_date?.value || record.stock_in_date || '',
      stock_number: record.stock_number?.value || record.stock_number || '',
      vin_number: record.vin_number?.value || record.vin_number || '',
      sale_type: record.sale_type?.value || record.sale_type || '',
      marketed_status: record.marketed_status?.value || record.marketed_status || '',
      inventory_cost: record.inventory_cost ? `$${record.inventory_cost.toLocaleString()}` : '',
      sale_price: record.sale_price ? `$${record.sale_price.toLocaleString()}` : '',
      internet_price: record.internet_price ? `$${record.internet_price.toLocaleString()}` : '',
      make: record.make?.value || record.make || '',
      model: record.model?.value || record.model || '',
      model_year: record.model_year?.value || record.model_year || '',
      // submitted_at: record.submitted_at?.value || record.submitted_at || ''
    }));

    // Create the PDF table using autoTable
    doc.autoTable({
      head: [columns.map(col => col.header)], // Define the headers
      body: rows.map(row => columns.map(col => row[col.dataKey])), // Map each row
      theme: 'striped',
      styles: { cellPadding: 2, fontSize: 10 },
      headStyles: { fillColor: [41, 128, 185] }, // Header color
    });

    // Save the PDF
    doc.save('inventory_data.pdf');
    setShowDownloadOptions(false); // Close the dropdown after download
  };

  const generateCSV = (data) => {
    if (data.length === 0) return '';

    const headers = Object.keys(data[0]).join(',');
    const rows = data.map(row => 
      Object.keys(row).map(field => `"${row[field]?.value || row[field]}"`).join(',')
    ).join('\n');

    return `${headers}\n${rows}`;
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const getStatusClass = (status) => {
    if (status === 'sold') {
      return 'status-sold';
    } else if (status === 'available') {
      return 'status-available';
    }
    return '';
  };

  return (
    <div className="inventory-data-container">
      {/* Update Record Section */}
      {selectedRecord && (
        <div className={`update-section ${showUpdateSection ? 'expanded' : ''}`}>
          <button onClick={() => setShowUpdateSection(!showUpdateSection)}>
            {showUpdateSection ? 'Hide Update Section' : 'Show Update Section'}
          </button>
          {showUpdateSection && (
            <div>
              <h3>Update Record</h3>
              {Object.keys(selectedRecord).map((field) => (
                <label key={field}>
                  {field.replace(/_/g, ' ')}:
                  <input 
                    type="text"
                    name={field}
                    value={selectedRecord[field] || ''}
                    onChange={handleFieldChange}
                  />
                </label>
              ))}
              <button onClick={handleUpdate}>Submit Update</button>
            </div>
          )}
        </div>
      )}

      <h2>Inventory Data</h2>

      {/* Filter Section */}
      <div className="filter-section">
        <div className="filter-group">
          <label>Start Date:</label>
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </div>
        <div className="filter-group">
          <label>End Date:</label>
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </div>
        <div className="filter-group">
          <label>Sale Type:</label>
          <select value={saleType} onChange={(e) => setSaleType(e.target.value)}>
            <option value="">All</option>
            <option value="New">New</option>
            <option value="Used">Used</option>
          </select>
        </div>
        <div className="filter-group">
          <label>Stock Number:</label>
          <input type="text" value={stockNumber} onChange={(e) => setStockNumber(e.target.value)} />
        </div>

          {/* Apply Filters Button */}
        <button className="filter-section-button" onClick={handleFilter}>
          Apply Filters
        </button>

        {/* Download Dropdown */}
        <div className="filter-section-right download-dropdown-container">
          <button
            onClick={() => setShowDownloadOptions(!showDownloadOptions)}
            className="download-button"
          >
            <FontAwesomeIcon icon={faDownload} className="download-icon" title="Download" />
          </button>
          {showDownloadOptions && (
            <div className="download-dropdown">
              <button onClick={handleDownloadCSV}>Download CSV</button>
              <button onClick={handleDownloadPDF}>Download PDF</button>
            </div>
          )}
        </div>
      </div>

      {/* Inventory Table */}
      <div className="table-wrapper table-wrapper-top-scroll">
        <div className="table-scroll-wrapper">
          <table className="inventory-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('stock_in_date')}>
                  Date Stocked <FontAwesomeIcon icon={getSortIcon('stock_in_date')} />
                </th>
                <th onClick={() => handleSort('stock_number')}>
                  Stock Number <FontAwesomeIcon icon={getSortIcon('stock_number')} />
                </th>
                <th onClick={() => handleSort('vin_number')}>
                  VIN <FontAwesomeIcon icon={getSortIcon('vin_number')} />
                </th>
                <th onClick={() => handleSort('sale_type')}>
                  Sale Type <FontAwesomeIcon icon={getSortIcon('sale_type')} />
                </th>
                <th onClick={() => handleSort('marketed_status')}>
                  Marketed As <FontAwesomeIcon icon={getSortIcon('marketed_status')} />
                </th>
                <th onClick={() => handleSort('inventory_cost')}>
                  Inventory Cost <FontAwesomeIcon icon={getSortIcon('inventory_cost')} />
                </th>
                <th onClick={() => handleSort('sale_price')}>
                  Sale Price <FontAwesomeIcon icon={getSortIcon('sale_price')} />
                </th>
                <th onClick={() => handleSort('internet_price')}>
                  Internet Price <FontAwesomeIcon icon={getSortIcon('internet_price')} />
                </th>
                <th onClick={() => handleSort('make')}>
                  Make <FontAwesomeIcon icon={getSortIcon('make')} />
                </th>
                <th onClick={() => handleSort('model')}>
                  Model <FontAwesomeIcon icon={getSortIcon('model')} />
                </th>
                <th onClick={() => handleSort('model_year')}>
                  Model Year <FontAwesomeIcon icon={getSortIcon('model_year')} />
                </th>
                <th onClick={() => handleSort('submitted_at')}>
                  Last Updated <FontAwesomeIcon icon={getSortIcon('submitted_at')} />
                </th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((record) => {
                const uniqueKey = `${record.stock_number}_${record.submitted_at?.value || record.submitted_at}`;

                return (
                  <tr key={uniqueKey} onClick={() => handleCellClick(record)}>
                    {Object.keys(record).map((field, idx) => (
                      <td key={`${field}_${uniqueKey}_${idx}`}
                      className={field === 'status' ? getStatusClass(record[field]?.value || record[field]) : ''}
                      >
                        {record[field]?.value || record[field]} {/* Adjust for nested objects */}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="pagination">
        {[...Array(Math.ceil(filteredData.length / recordsPerPage)).keys()].map((num) => (
          <button
            key={num + 1}
            onClick={() => paginate(num + 1)}
            className={currentPage === num + 1 ? 'active' : ''}
          >
            {num + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default InventoryData;
