// App.js
import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import './App.css';
import MainLayout from './components/MainLayout';
import LoginPage from './pages/LoginPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import HomePage from './pages/HomePage';
import AccountDetails from './pages/AccountDetails';
import SalesDashboard from './pages/Dashboards/SalesDashboard';
import CustomerDashboard from './pages/Dashboards/CustomerDashboard';
import InventoryDashboard from './pages/Dashboards/InventoryDashboard';
import SalesForm from './pages/Forms/SalesForm';
import CustomerLogForm from './pages/Forms/CustomerLogForm';
import InventoryForm from './pages/Forms/InventoryForm';
import PersonnelData from './pages/Data/PersonnelData';
import InventoryData from './pages/Data/InventoryData';
import VariableLeaders from './pages/Leaders/VariableLeaders';
import FixedLeaders from './pages/Leaders/FixedLeaders';
import SalesReport from './pages/Reports/SalesReport';
import SwitchboardPage from './pages/Admin/SwitchBoardPage';
import NotAuthorizedPage from './pages/NotAuthorizedPage';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      setIsLoggedIn(true);
      setIsFirstLogin(sessionStorage.getItem('firstLogin') === 'true');
      fetchUserData(token);
    }
  }, []);

  const fetchUserData = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/getUserDetails`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }
      const userData = await response.json();
      setUser({
        firstName: userData.first_name,
        lastName: userData.last_name,
        email: userData.email,
        rooftop: userData.rooftop,
        group: userData.enterprise,
        username: userData.username,
        userRole: userData.user_role,
        activeDate: userData.active_date,
        token: token,
        permissions: userData.permissions, // Include permissions
      });
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleLogin = (token, firstLogin) => {
    sessionStorage.setItem('accessToken', token);
    sessionStorage.setItem('firstLogin', firstLogin ? 'true' : 'false');
    setUser(null); // Reset user state before fetching new data
    setIsLoggedIn(true);
    setIsFirstLogin(firstLogin);
    fetchUserData(token);
    navigate('/'); // Navigate to the home page after login
  };

  const handleLogout = () => {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('firstLogin');
    setIsLoggedIn(false);
    setUser(null);
  };

  const handlePasswordChange = () => {
    sessionStorage.setItem('firstLogin', 'false');
    setIsFirstLogin(false);
  };

  if (!isLoggedIn) {
    return <LoginPage onLogin={handleLogin} />;
  }

  if (isFirstLogin) {
    return <ChangePasswordPage onChangePassword={handlePasswordChange} />;
  }

  // Wait for user data to be loaded before rendering the main content
  if (!user) {
    return <div>Loading user data...</div>;
  }

  return (
    <MainLayout onLogout={handleLogout} user={user}>
      <Routes>
        <Route path="/" element={<HomePage user={user} />} />
        <Route
          path="/dashboards/sales"
          element={
            <ProtectedRoute user={user} allowedPermissions={['viewSalesDashboard']}>
              <SalesDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboards/customer"
          element={
            <ProtectedRoute user={user} allowedPermissions={['viewCustomerDashboard']}>
              <CustomerDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboards/inventory"
          element={
            <ProtectedRoute user={user} allowedPermissions={['viewInventoryDashboard']}>
              <InventoryDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/forms/sales"
          element={
            <ProtectedRoute user={user} allowedPermissions={['submitSalesForm']}>
              <SalesForm user={user} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/forms/customer"
          element={
            <ProtectedRoute user={user} allowedPermissions={['submitCustomerForm']}>
              <CustomerLogForm user={user} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/forms/inventory"
          element={
            <ProtectedRoute user={user} allowedPermissions={['submitInventoryForm']}>
              <InventoryForm user={user} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/data/inventory"
          element={
            <ProtectedRoute user={user} allowedPermissions={['viewInventoryData']}>
              <InventoryData />
            </ProtectedRoute>
          }
        />
        <Route
          path="/data/personnel"
          element={
            <ProtectedRoute user={user} allowedPermissions={['viewPersonnelData']}>
              <PersonnelData />
            </ProtectedRoute>
          }
        />
        <Route
          path="/leaders/variable"
          element={
            <ProtectedRoute user={user} allowedPermissions={['viewVariableLeaders']}>
              <VariableLeaders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/leaders/fixed"
          element={
            <ProtectedRoute user={user} allowedPermissions={['viewFixedLeaders']}>
              <FixedLeaders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports/sales"
          element={
            <ProtectedRoute user={user} allowedPermissions={['viewSalesReport']}>
              <SalesReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute user={user}>
              <div>Account Page</div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/account-details"
          element={
            <ProtectedRoute user={user}>
              <AccountDetails user={user} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/switchboard"
          element={
            <ProtectedRoute user={user} allowedRoles={['admin']}>
              <SwitchboardPage user={user} />
            </ProtectedRoute>
          }
        />
        <Route path="/not-authorized" element={<NotAuthorizedPage />} />
      </Routes>
    </MainLayout>
  );
}

export default App;
