import React, { useState } from 'react';
import './AccountDetails.css';

const AccountDetails = ({ user }) => {
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('http://localhost:4000/send-support-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        message: message,
        from: user.email,
      }),
    })
      .then(response => response.json())
      .then(() => setSuccess(true))
      .catch(() => alert('Error submitting the form'));

    setMessage('');
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  // Extract the value from the nested active_date object and format the date
  const formattedDate = new Date(user.activeDate?.value).toLocaleDateString();

  return (
    <div className="account-details-container">
      <h2>Account Details</h2>
      <p><strong>First Name:</strong> {user.firstName}</p>
      <p><strong>Last Name:</strong> {user.lastName}</p>
      <p><strong>Email:</strong> {user.email}</p>
      <p><strong>Dealer:</strong> {user.rooftop}</p>
      <p><strong>Group:</strong> {user.group}</p>
      <p><strong>Username:</strong> {user.username}</p>
      <p><strong>Role:</strong> {user.userRole}</p>
      <p><strong>Active Since:</strong> {formattedDate}</p>

      <div className="support-section">
        <h3>Need Help? Contact Us</h3>
        <p><strong>Phone:</strong> 704-256-0026</p>
        <p><strong>Email:</strong> hello@optisight.io</p>

        <form onSubmit={handleSubmit} className="support-form">
          <label>
            Your Message:
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </label>
          <button type="submit">Submit</button>
        </form>

        {success && <p>Your message has been sent successfully!</p>}
      </div>
    </div>
  );
};

export default AccountDetails;
