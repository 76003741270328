import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './LineChartD3.css'; // Import the CSS file for styling

const LineChartD3 = ({ data, selectedMonth, selectedYear, toggle, saleType }) => {
  const svgRef = useRef();
  const [currentToggle, setCurrentToggle] = useState(toggle);

  const handleToggle = () => {
    setCurrentToggle(currentToggle === 'total_deals' ? 'total_gross_profit' : 'total_deals');
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const width = 800;
      const height = 400;
      const margin = { top: 20, right: 30, bottom: 50, left: 70 };

      d3.select(svgRef.current).selectAll('*').remove();

      const svg = d3.select(svgRef.current)
        .attr('viewBox', `0 0 ${width} ${height}`) // Make the chart responsive
        .attr('preserveAspectRatio', 'xMinYMin meet') // Maintain the aspect ratio
        .classed('svg-content-responsive', true); // Add responsive class

      const chartWidth = width - margin.left - margin.right;
      const chartHeight = height - margin.top - margin.bottom;

      const chart = svg.append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      // Filter out invalid data points (e.g., null values for the current toggle metric)
      const filteredData = data.filter(d => d[currentToggle] !== null);

      const xScale = d3.scaleLinear()
        .domain([1, d3.max(filteredData, d => d.day)]) // Set domain from day 1 to max day
        .range([0, chartWidth]); // Set range from 0 to chart width

      const yMax = d3.max(filteredData, d => d[currentToggle]);
      const yScale = d3.scaleLinear()
        .domain([0, yMax])
        .range([chartHeight, 0]);

      // Define the line generator function
      const line = d3.line()
        .x(d => xScale(d.day))
        .y(d => yScale(d[currentToggle]))
        .curve(d3.curveMonotoneX); // Ensure smooth but accurate line

      const tooltip = d3.select('body').append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0);

      chart.append('path')
        .datum(filteredData) // Use filtered data
        .attr('fill', 'none')
        .attr('stroke', '#007FFF')
        .attr('stroke-width', 4)
        .attr('d', line);

      // Add vertical line for cursor tracking
      const cursorLine = chart.append('line')
        .attr('stroke', '#ffffff')
        .attr('stroke-width', 1)
        .attr('opacity', 0);

      // Add circle to indicate the point being hovered
      const hoverCircle = chart.append('circle')
        .attr('r', 6)
        .attr('fill', '#ffffff')
        .attr('stroke', '#007FFF')
        .attr('stroke-width', 2)
        .attr('opacity', 0);

      // Add invisible hover areas for interaction
      chart.selectAll('.hover-area')
        .data(filteredData)
        .enter()
        .append('circle')
        .attr('class', 'hover-area')
        .attr('cx', d => xScale(d.day))
        .attr('cy', d => yScale(d[currentToggle]))
        .attr('r', 10)
        .attr('fill', 'transparent')
        .on('mouseover', (event, d) => {
          tooltip.transition().duration(200).style('opacity', 0.9);

          const totalDeals = d.total_deals !== null ? d.total_deals.toLocaleString() : 'N/A';
          const totalGrossProfit = d.total_gross_profit !== null ? `$${d.total_gross_profit.toLocaleString()}` : 'N/A';

          tooltip.html(`
            <strong>Date:</strong> ${new Date(selectedYear, selectedMonth - 1, d.day).toLocaleDateString()}<br>
            <strong>Total Deals:</strong> ${totalDeals}<br>
            <strong>Gross Profit:</strong> ${totalGrossProfit}
          `)
            .style('left', (event.pageX + 10) + 'px')
            .style('top', (event.pageY - 28) + 'px');

          cursorLine.attr('x1', xScale(d.day))
            .attr('x2', xScale(d.day))
            .attr('y1', 0)
            .attr('y2', chartHeight)
            .attr('opacity', 1);

          hoverCircle.attr('cx', xScale(d.day))
            .attr('cy', yScale(d[currentToggle]))
            .attr('opacity', 1);
        })
        .on('mousemove', (event) => {
          tooltip.style('left', (event.pageX + 10) + 'px').style('top', (event.pageY - 28) + 'px');
        })
        .on('mouseout', () => {
          tooltip.transition().duration(500).style('opacity', 0);
          cursorLine.attr('opacity', 0);
          hoverCircle.attr('opacity', 0);
        });

      chart.append('g')
        .attr('class', 'axis')
        .attr('transform', `translate(0,${chartHeight})`)
        .call(d3.axisBottom(xScale).ticks(8).tickSize(10).tickPadding(10).tickFormat(d => `Day ${d}`));

      chart.append('g')
        .attr('class', 'axis')
        .call(d3.axisLeft(yScale).ticks(6).tickSize(10).tickPadding(10).tickFormat(d => currentToggle === 'total_gross_profit' ? `$${d.toLocaleString()}` : d.toLocaleString()));

      svg.selectAll('path.domain').style('stroke', 'white');
      svg.selectAll('line').style('stroke', 'white');

      const average = Math.round(d3.mean(filteredData, d => d[currentToggle]));

      chart.append('line')
        .attr('x1', 0)
        .attr('x2', chartWidth)
        .attr('y1', yScale(average))
        .attr('y2', yScale(average))
        .attr('stroke', '#d3d3d3')
        .attr('stroke-dasharray', '3 3');

      chart.append('text')
        .attr('x', chartWidth - 60)
        .attr('y', yScale(average) - 10)
        .attr('fill', '#333')
        .text(`Avg: ${currentToggle === 'total_gross_profit' ? `$${average.toLocaleString()}` : average.toLocaleString()}`);      
    }

    return () => {
      d3.select('.tooltip').remove();
    };
  }, [data, currentToggle, saleType, selectedMonth, selectedYear]); // Add missing dependencies

  // Define the sale type label
  const saleTypeLabel = saleType === 'new' ? 'New' : saleType === 'used' ? 'Used' : 'All';

  return (
    <div className="chart-wrapper">
      <div className="chart-header">
        <h2 className="chart-title">
          {saleTypeLabel} {currentToggle === 'total_deals' ? `Total Deals in ${new Date(selectedYear, selectedMonth - 1).toLocaleString('default', { month: 'long' })} ${selectedYear}` : `Total Gross Profit in ${new Date(selectedYear, selectedMonth - 1).toLocaleString('default', { month: 'long' })} ${selectedYear}`}
        </h2>
        <div className="toggle-switch">
          <label className="switch">
            <input type="checkbox" checked={currentToggle === 'total_gross_profit'} onChange={handleToggle} />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default LineChartD3;
